import React from 'react'
import { navigate } from 'gatsby'
import { Link as ReakitLink } from 'reakit'

const handleNavigation = url => event => {
  event.preventDefault()
  navigate(url)
}

// note that using Gatsby.Link results in invalid props warnings, prefer Reakit.Link
// (dropping html-unfriendly props using {excluded, ...rest} may be necessary)
const Link = ({ children, to, elementRef, opaque, use, ...rest }) => (
  <ReakitLink href={to} onClick={handleNavigation(to)} {...rest}>
    {children}
  </ReakitLink>
  // <a href={to} onClick={handleNavigation(to)} {...rest}>{children}</a>
)

export default Link
