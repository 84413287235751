import { useState, useEffect } from 'react'
import isBrowser from './isBrowser'

const getWidth = () => (isBrowser() ? window.innerWidth : 320)

// https://gist.github.com/gaearon/cb5add26336003ed8c0004c4ba820eae
function useWindowWidth() {
  const [width, setWidth] = useState(getWidth())

  useEffect(() => {
    const handleResize = () => setWidth(getWidth())
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []) // [] update on none; only run on mount!

  return width
}

export default useWindowWidth
