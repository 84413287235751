import { styled, Flex } from 'reakit'
import { palette as p } from 'styled-tools'

const PageWrapper = styled(Flex).attrs(() => ({
  className: 'PageWrapper',
}))`
  flex-direction: column;
  align-items: center;

  min-height: 100vh;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;

  background-color: ${p('background', -1)};
  color: ${p('backgroundText', -1)};

  & > main {
    flex: 1;
    width: 100%;
  }
`

export default PageWrapper
