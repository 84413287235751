import anindilyakwaAudio from '../assets/audio/Disability-in-the-Bush-Anindilyakwa-2020-07-17.mp3'
import englishAudio from '../assets/audio/Disability-in-the-Bush-English-2020-05-25.mp3'
import pitjiAudio from '../assets/audio/Disability-in-the-Bush-Pitji-2020-05-25.mp3'
import tiwiAudio from '../assets/audio/Disability-in-the-Bush-Tiwi-2020-08-27.mp3'
import wArandaAudio from '../assets/audio/Disability-in-the-Bush-W-Aranda-2020-05-25.mp3'
import yolgnuAudio from '../assets/audio/Disability-in-the-bush-Yolngu-2020-07-17.mp3'

export default {
  heading: `Hear in Language`,
  explanation: `Select a language to hear an overview of the NDIS. You can continue looking through the app while this is playing. Click the 'x' button to close the audio bar.`,
  closeAlt: `Close audio modal`,
  languages: [
    { name: 'Anindilyakwa', path: anindilyakwaAudio },
    { name: 'English', path: englishAudio },
    { name: 'Pitji', path: pitjiAudio },
    { name: 'Tiwi', path: tiwiAudio },
    { name: 'Western Aranda', path: wArandaAudio },
    { name: 'Yolgnu', path: yolgnuAudio },
  ],
}
