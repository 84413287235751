const GATSBY_BASE_URL = process.env.GATSBY_BASE_URL

/**
 * Transform CMS URLs into root relative URLs by
 * stripping the hostname off the URL.
 *
 * Pass through external links.
 *
 * Eg:
 * - In: http://cms.disabilityinthebush.com/carers-support-workers/
 * - Out: /carers-support-workers/
 *
 * GATSBY_BASE_URL includes `https://`
 *
 * @param {*} url
 * @returns String: processed URL
 */
const urlToLocal = url => {
  // It's possible that the CMS may have content containing http links, so normalise
  let cmsHostname = GATSBY_BASE_URL.replace(/https?:\/\//, '')

  if (url.indexOf(cmsHostname) > -1) {
    let pathname = url.replace(cmsHostname, '').replace(/https?:\/\//, '')
    // console.log({ url, cmsHostname, pathname })
    return pathname
  }
  return url
}

export default urlToLocal
