import React, { Fragment, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import {
  Box,
  List,
  Toolbar,
  Block,
  Sidebar,
  Button,
  Backdrop,
  Portal,
} from 'reakit'
import { palette as p } from 'styled-tools'

import AudioBarContext from './AudioBarContext'
import Link from './Link'
import Logo from './Logo'
import SVG from './SVG'
import HearInLanguageButton from './HearInLanguageButton'
import { palette as colours } from '../theme/theme'
import maskLowerDotted from '../assets/mask--lower--dotted.svg'
import dotLineGrey from '../assets/dot-line--grey.svg'
import motif from '../assets/motif--header.svg'
import iconMenu from '!raw-loader!../assets/icon-menu.svg' //
import iconClose from '!raw-loader!../assets/icon-close.svg' //
import disabilityInTheBush from '!raw-loader!../assets/disability-in-the-bush.svg' //
import disabilityInTheBushOneLine from '!raw-loader!../assets/disability-in-the-bush--one-line.svg' //
import dotOrange from '../assets/dot--orange.svg'
import dotYellow from '../assets/dot--yellow.svg'
import urlToLocal from '../utils/urlToLocal'

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 0;
  background: ${colours.ipGold} no-repeat -50px -50px url("${motif}");
  background-size: 65%;

  @media print {
    display: inherit;
  }

  &:after {
    position: relative;
    bottom: -1px;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 10px;
    background: repeat-x bottom left url("${maskLowerDotted}");
  }
`

const MobileToolbar = styled(Toolbar)`
  display: flex;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;

  @media (max-width: 640px) {
    min-height: 144px;
  }
`
const StyledToolbarContent = styled(Toolbar.Content)`
  display: flex;
`

const StyledSidebar = styled(Sidebar)`
  overflow: scroll;
  width: 80%;
  min-width: 320px;
  padding: 0;
  background-color: ${colours.ipYellow};

  @media (min-width: 414px) {
    width: 340px;
  }
`
const StyledHeader = styled.header`
  padding: 1rem 1rem 2rem;
  min-height: 100px;

  &:after {
    position: relative;
    top: 50px;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 10px;
    background: repeat-x bottom left url("${dotLineGrey}");
  }
`

const StyledSidebarHide = styled(StyledSidebar.Hide)`
  display: block;
  height: auto;
  width: auto;
`
const StyledIconButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: calc(50px + 1rem);
  padding: 0.5rem;
  height: initial;
  max-height: initial;

  @media print {
    display: none;
  }
`
const StyledHearInLanguageButtonWrapper = styled.div`
  margin: 1rem 0;
  padding-left: 1rem;
`
const StyledList = styled(List)`
  margin: 1rem 0;
  list-style-image: url(${dotOrange});
`
const InnerList = styled(List)`
  margin: 0;
  list-style-image: url(${dotYellow});
`
const StyledListItem = styled.li`
  margin: 0;
  padding-left: 1rem;
  border-bottom: 2px solid white;
  list-style-position: inside;
`
const StyledInnerListItem = styled(StyledListItem)`
  &:first-child {
    border-top: 2px solid white;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`
const StyledLink = styled(Link)`
  display: inline-block;
  width: 86%;
  padding: 1rem 0.5rem;
  color: ${p('black')};
  line-height: 1.35;
`
const StyledTitleLink = styled(Link)`
  display: inline-block;
  width: 76%;

  @media (min-width: 375px) {
    width: 86%;
  }
`

const SidebarContent = ({ mainMenu, sidebar, setNavState }) => {
  useEffect(() => {
    setNavState(sidebar.visible)
  }, [sidebar.visible])

  return (
    <Fragment>
      <Block>
        <StyledIconButton
          palette="secondary"
          use={StyledSidebar.Show}
          {...sidebar}
        >
          <SVG svg={iconMenu} alt="Open Sidebar!!" />
        </StyledIconButton>
      </Block>

      <Backdrop
        use={[Portal, Sidebar.Hide]}
        {...sidebar}
        // FIXME: not sure why I have to override these here - using StyledBackdrop fails
        backgroundColor={colours.shadow[3]}
      />
      <StyledSidebar use={Portal} {...sidebar}>
        <Block relative>
          <StyledHeader>
            <Toolbar.Focusable use={Link} to="/">
              {/* FIXME? */}
              <Logo marginBottom="1rem" />
            </Toolbar.Focusable>

            <StyledIconButton
              use={StyledSidebarHide}
              palette="secondary"
              {...sidebar}
            >
              <SVG svg={iconClose} alt="Close navigation menu" />
            </StyledIconButton>
          </StyledHeader>

          <StyledHearInLanguageButtonWrapper>
            <HearInLanguageButton sidebar={sidebar} />
          </StyledHearInLanguageButtonWrapper>

          <StyledList>
            {mainMenu.items.map(
              ({ title, url, wordpress_children, wordpress_id }, i) => (
                <StyledListItem key={wordpress_id}>
                  <StyledLink to={urlToLocal(url)}>{title}</StyledLink>

                  {wordpress_children && (
                    <InnerList>
                      {wordpress_children.map(
                        ({ title, url, wordpress_id }, i) => (
                          <StyledInnerListItem key={wordpress_id}>
                            <StyledLink to={urlToLocal(url)}>
                              {title}
                            </StyledLink>
                          </StyledInnerListItem>
                        )
                      )}
                    </InnerList>
                  )}
                </StyledListItem>
              )
            )}
          </StyledList>
        </Block>
      </StyledSidebar>
    </Fragment>
  )
}

const Mobile = ({ mainMenu, windowWidth }) => {
  return (
    <MobileToolbar>
      <StyledToolbarContent>
        <Toolbar.Focusable use={StyledTitleLink} to="/">
          <SVG
            svg={
              windowWidth > 640
                ? disabilityInTheBushOneLine
                : disabilityInTheBush
            }
            maxWidth="98%"
            maxHeight="120px"
            alt="Disability in the bush"
          />
        </Toolbar.Focusable>
      </StyledToolbarContent>

      <StyledToolbarContent align="end">
        <StyledSidebar.Container context="sidebar">
          {sidebar => (
            <AudioBarContext.Consumer>
              {audioBarContext => (
                <SidebarContent
                  mainMenu={mainMenu}
                  sidebar={sidebar}
                  setNavState={audioBarContext.setNavState}
                />
              )}
            </AudioBarContext.Consumer>
          )}
        </StyledSidebar.Container>
      </StyledToolbarContent>
    </MobileToolbar>
  )
}

const Header = props => {
  const data = useStaticQuery(graphql`
    query mainMenu {
      allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main" } }) {
        edges {
          node {
            name
            count
            items {
              order
              title
              url
              wordpress_id
              wordpress_children {
                title
                url
                wordpress_id
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper {...props}>
      <Mobile
        mainMenu={data.allWordpressWpApiMenusMenusItems.edges['0'].node}
        windowWidth={props.windowWidth}
      />
    </Wrapper>
  )
}

export default Header
