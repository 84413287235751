import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Provider } from 'reakit'

import useWindowWidth from '../utils/useWindowWidth'
import GlobalStyle from './GlobalStyle'
import theme from '../theme/theme'
import PageWrapper from './PageWrapper'
import Header from '../components/Header'
import Footer from '../components/Footer'

const QUERY = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children, isHome }) => {
  const width = useWindowWidth()

  return (
    <Provider theme={theme}>
      <>
        <GlobalStyle />
        <StaticQuery query={QUERY}>
          {data => (
            <PageWrapper>
              <Header
                siteTitle={data.site.siteMetadata.title}
                windowWidth={width}
              />
              <main>{children}</main>
              <Footer minimal={!isHome} />
            </PageWrapper>
          )}
        </StaticQuery>
      </>
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
