import { createGlobalStyle } from 'reakit'
import { palette as p } from 'styled-tools'
import openDyslexicBold from '../assets/fonts/OpenDyslexic3-Bold.ttf'
import openDyslexicRegular from '../assets/fonts/OpenDyslexic3-Regular.ttf'
import mairyBold from '../assets/fonts/mairy-bold-webfont.woff'
import mairyBold2 from '../assets/fonts/mairy-bold-webfont.woff2'
import maskLower from '../assets/mask--lower.svg'
import maskUpper from '../assets/mask--upper.svg'

// Note: changes to this file don't live-refresh

// eslint-disable-next-line no-unused-expressions
const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Mairy';
      src: url(${mairyBold2}) format('woff2'),
        url(${mairyBold}) format('woff');
      font-weight: 600;
      font-style: normal;
  }

  @font-face {
      font-family: 'OpenDyslexic';
      src: url(${openDyslexicRegular}) format('truetype');
      font-weight: 400;
      font-style: normal;
  }

  @font-face {
      font-family: 'OpenDyslexicBold';
      src: url(${openDyslexicBold}) format('truetype');
      font-weight: 700;
      font-style: normal;
  }

  html {
    font-size: 18px;
    line-height: 1.5;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    font-family: arial, sans-serif;

    &.modal-open {
      height: 100vh;
      max-height: 100vh;
      overflow: hidden;
    }
  }

  #___gatsby > div {
    background-color: #ddd;
  }

  a {
    color: currentColor;

    &:-webkit-any-link:active {
      color: currentColor;
    }
  }

  .fixme {
    background-color: pink;
    font-style: italic;
    opacity: 0.8;
  }

  h1, h2, h3, h4 {
    font-family: OpenDyslexicBold, arial, sans-serif;
    line-height: 1.5;
    font-weight: 700;
    word-spacing: -0.3em;
    /* letter-spacing: 0rem; */
  }

  p {
    margin-bottom: 1rem;
    font-size: 1.1em;
  }

  ul {
    padding-left: 1.5rem;
  }

  /* wp-block-embed-youtube wp-block-embed is-type-video is-provider-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio */
  .wp-block-embed-youtube {
    margin: 0 0 1rem 0;
  }
  iframe {
    max-width: 100%;
  }

  .wp-block-image {
    margin: 0;
  }
  .wp-block-image img {
    max-width: 100%;
    height: auto;
  }
  /* .wp-block-image .size-large {} */
  .wp-block-buttons {
    display: flex;
    max-width: 100%;
    flex-direction: column;

    @media (min-width: 640px) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      max-width: auto;
    }
  }

  /* Adhoc styles for the lo-res disabilities-collage-2.jpg */
  .image-group .wp-block-group__inner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .image-group .wp-block-group__inner-container .wp-block-image {
      width: 100%;
      min-width: 200px;
      max-width: 244px;
  }

  .shortcode-placeholder {
    background-color: #ddd;
    padding: 0.5rem;
    font-family: monospace;
  }

  .u-lstn {
    list-style-type: none;
  }
  .u-tdn {
    text-decoration: none;
  }

  @media print {
    /*
    Prefer applying styles to components.
    Note: background images and colors are disabled when printing
    */
    h1, h2, h3 {
      break-after: avoid;
    }
  }

  /* FIXME: for some reason, these styles work, but block any styles that follow from rendering! */
  blockquote, /* .wp-block-quote, */
  .blockquote {
    background-color: ${p('ipYellowDark')};
    margin: 1rem -1rem;
    padding: 1rem;
    overflow: hidden;
    font-size: 1.2rem;
    font-style: italic;
    /* font-family: OpenDyslexic; */

    &:before {
      position: relative;
      top: -1rem;
      left: 0;
      display: block;
      content: "";
      width: 100vw;
      height: 10px;
      margin: 0 -1rem;
      background: repeat-x bottom left url("${maskUpper}");
    }

    &:after {
      position: relative;
      bottom: -1rem;
      left: 0;
      display: block;
      content: "";
      width: 100vw;
      height: 10px;
      margin: 0 -1rem;
      background: repeat-x bottom left url("${maskLower}");
    }

    &.large-italic {
      font-size: 1.2em;
      font-style: italic;
    }

    &.with-icon p {
      position: relative;
      margin-left: 45px;

      &:before {
        content: '"';
        position: absolute;
        top: -1rem;
        left: -45px;
        display: inline;
        font-family: OpenDyslexicBold;
        font-size: 3rem;
        color: white;
    }
  }
`

export default GlobalStyle
