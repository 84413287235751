import React from 'react'
import { Overlay } from 'reakit'
import styled from 'styled-components'
import buttonHear from '../assets/button-hear--large.svg'
import urlToLocal from '../utils/urlToLocal'
import AudioModal from './AudioModal'
import Link from './Link'

const StyledLink = styled(Link)`
  transition: 100ms all;

  &:hover {
    cursor: pointer;
    transform: translateY(-2px);
  }
  &:active {
    transform: translateY(2px);
  }
`

/** Based on CallToAction.js */
const Button = ({ to, children, showNext, onClick }) => {
  const href = urlToLocal(to || '#')
  const isExternal = /^(mailto|https?)/.test(href)
  const component = isExternal ? 'a' : Link
  const next = showNext ? <span key="next">Next:&nbsp;</span> : null

  return (
    <StyledLink
      use={component}
      to={href}
      onClick={onClick}
      href={isExternal ? href : null}
      children={[next, <span key="children">{children}</span>]}
    />
  )
}

const StyledButton = styled(Button)`
  align-self: center;

  @media (min-width: 768px) {
    align-self: flex-start;
  }
`
const StyledImg = styled.img`
  margin-right: 1rem;
`

const HearInLanguageButton = ({ sidebar }) => (
  <Overlay.Container>
    {overlay => (
      <>
        <StyledButton
          onClick={() => {
            if (sidebar && sidebar.visible) sidebar.hide()
            overlay.show()
          }}
        >
          <StyledImg src={buttonHear} alt="Hear in Language" />
        </StyledButton>
        <AudioModal overlay={overlay} />
      </>
    )}
  </Overlay.Container>
)

export default HearInLanguageButton
