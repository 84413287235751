import React from 'react'
import styled, { css } from 'styled-components'
import { palette as p } from 'styled-tools'
import { spacing } from '../theme/theme'
import Link from './Link'
import dotLineGrey from '../assets/dot-line--grey.svg' // don't raw load?
import interplayLogo from '../assets/interplay-logo-landscape.svg' // 718 x 203
import nintiLogo from '../assets/Ninti-One-Logo-2019-300x156.png' // 300 x 156
import ndisLogo from '../assets/NDIA_Logo-web.png' // 512 x 342

const StyledFooter = styled.footer`
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${spacing.desktop};
  margin: ${props => (props.minimal ? '1.5rem 1rem 1rem' : '3rem 1rem 1rem')};
  padding-top: ${props => (props.minimal ? '1.5rem' : '1.5rem')};

${props =>
  // don't show the footer on non-homepages
  props.minimal &&
  css`
    @media print {
      display: none;
    }
  `}

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 10px;
    background: repeat-x bottom left url("${dotLineGrey}");
  }
`

const StyledLink = styled(Link)`
  display: flex;
  flex-flow: column nowrap;

  @media (min-width: 640px) {
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    margin: 0 1rem;
  }

  &:hover {
    text-decoration: none;
  }
`

// 512 x 342
const NDISLogo = styled.img`
  width: 260px;
  height: auto;
  max-width: 190px;
  margin-top: 1rem;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    flex: 1;
    margin-right: 1rem;
  }
`

const MiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  max-width: 100%;
  margin-bottom: 1rem;

  @media (min-width: 640px) {
    display: inline-flex;
    flex: 1;
  }
`

// image is 718 x 203 - ensure correct aspect ratio
const InterplayLogo = styled.img`
  /* max-width: 128px; */
  width: 45%;
  height: calc(45% * 203 / 718);
  margin-right: 1rem;

  @media (min-width: 640px) {
    width: 50%;
  }
`

// image is 300 x 156 - ensure correct aspect ratio
const NintiLogo = styled.img`
  flex: 0;
  /* max-width: 128px; */
  width: 40%;
  height: calc(40% * 156 / 300);

  @media (min-width: 640px) {
    flex: none;
  }
`

const Copyright = styled.div`
  width: 100%;
  text-align: center;
  color: ${p('black')};
`

const StyledPara = styled.p`
  max-width: 270px;
  margin: 0 1rem 0.5rem;
  text-align: center;
  font-style: italic;

  @media (min-width: 375px) {
    max-width: 340px;
  }
  @media (min-width: 510px) {
    max-width: 510px;
  }
`

const Footer = ({ minimal }) => (
  <StyledFooter minimal={minimal}>
    <StyledPara>
      We acknowledge the Traditional Owners of this Country and pay our respects
      to Elders past, present and emerging.
    </StyledPara>

    <StyledLink to="/about">
      {!minimal && (
        <>
          <NDISLogo src={ndisLogo} alt="NDIS logo" />
          <MiddleRow>
            <InterplayLogo src={interplayLogo} alt="Interplay Project logo" />
            <NintiLogo src={nintiLogo} alt="Ninti One logo" />
          </MiddleRow>
        </>
      )}
      <Copyright>© {new Date().getFullYear()}</Copyright>
    </StyledLink>
  </StyledFooter>
)

export default Footer
