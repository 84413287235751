import React from 'react'
import styled from 'styled-components'
import { css, Box } from 'reakit'
import { switchProp, ifProp, prop } from 'styled-tools'

import logoSquare from '!raw-loader!../assets/interplay-logo.svg' // 320 x 320
import logoLandscape from '!raw-loader!../assets/interplay-logo-landscape.svg' // 718 x 203

const Component = ({ square, ...props }) => (
  <Box
    {...props}
    dangerouslySetInnerHTML={{ __html: square ? logoSquare : logoLandscape }}
  />
)

const Logo = styled(Component)`
  ${switchProp(prop('size', 'medium'), {
    medium: css`
      // width: calc(40px * 3.536945813);
      width: ${ifProp('square', '40px', 'calc(40px * 3.536945813)')};
      height: 40px;
    `,
    large: css`
      // width: calc(120px * 3.536945813);
      width: ${ifProp('square', '120px', 'calc(120px * 3.536945813)')};
      height: 120px;
    `,
  })}

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  .cls-2 {
    fill: currentColor !important;
  }
`

export default Logo
