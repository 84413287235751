import iconClose from '!raw-loader!../assets/icon-close.svg'
import React, { useEffect } from 'react'
import { Backdrop, Button, Overlay, Portal } from 'reakit'
import styled from 'styled-components'
import content from '../content/audioModal'
import { palette as colours } from '../theme/theme'
import AudioBarContext from './AudioBarContext'
import AudioButton from './AudioButton'
import SVG from './SVG'

const StyledBackdrop = styled(Overlay.Hide)`
  z-index: 19900420;
`
const StyledOverlay = styled.div`
  border-radius: 0;
  max-height: 100vh;
  max-width: 380px;
  overflow: scroll;
  padding: 5rem 1.8rem 2rem;
  width: 100%;
  z-index: 19900430;
`
const Heading = styled.h1`
  color: ${colours.ipPurple};
  font: 32px/1 'OpenDyslexic', sans-serif;
  margin: 1rem 0 1.8rem;
`
const CloseButton = styled(Button)`
  display: block;
  height: auto;
  padding: 0.5rem 0.5rem 0;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`
const Explanation = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
`

const AudioModal = ({ overlay }) => {
  const setAudioPath = (event, audioBarContext, path) => {
    event.preventDefault()
    audioBarContext.setPath(path)
    overlay.hide()
  }

  useEffect(() => {
    if (overlay.visible) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [overlay.visible])

  return (
    <AudioBarContext.Consumer>
      {context => (
        <>
          <Backdrop use={[Portal, StyledBackdrop]} {...overlay} />
          <Overlay use={[Portal, StyledOverlay]} {...overlay}>
            <CloseButton
              aria-label="close"
              palette="secondary"
              onClick={overlay.hide}
            >
              <SVG svg={iconClose} alt={content.closeAlt} />
            </CloseButton>
            <Heading>{content.heading}</Heading>
            {content.languages.map(({ path, name }) => (
              <AudioButton
                key={name}
                onClick={e => setAudioPath(e, context, path)}
              >
                {name}
              </AudioButton>
            ))}
            <Explanation>{content.explanation}</Explanation>
          </Overlay>
        </>
      )}
    </AudioBarContext.Consumer>
  )
}

export default AudioModal
