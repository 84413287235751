import React from 'react'
import styled from 'styled-components'
import { Button } from 'reakit'

import { palette as colours } from '../theme/theme'
import buttonSvg from '../assets/button--yellow.svg'
import playIcon from '../assets/play-icon--purple.svg'

const StyledButton = styled(Button)`
  background: url(${buttonSvg}) center/100% 95% no-repeat transparent;
  display: block;
  height: 76px;
  margin-bottom: 1rem;
  max-width: 100%;
  padding-left: 90px;
  position: relative;
  text-align: left;
  transform: translateY(0);
  transition: 100ms all;
  width: 100%;
  line-height: 1;

  &:hover,
  &:active {
    box-shadow: none;
    transform: translateY(2px);
  }

  &:before {
    background: no-repeat center url("${playIcon}");
    content: "";
    display: inline-block;
    height: 52px;
    left: 15px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 59px;
  }
`

const Label = styled.span`
  color: ${colours.ipPurple};
  font: 28px/1 'OpenDyslexic', sans-serif;
  white-space: normal;
`

const AudioButton = ({ onClick, children }) => (
  <StyledButton onClick={onClick}>
    <Label>{children}</Label>
  </StyledButton>
)

export default AudioButton
